<template>
  <div class="content">
    <div class="heighttes">
      <el-form
          :inline="true"
          ref="sonform"
          :model="sonform"
          label-width="130px"
          :rules="rule"
      >
        <div class="SearchBar">
          <el-button @click="closeadd" size="mini" class="backbtn"
          >返回</el-button
          >

          <el-row>
            <el-col class="cow">
              <el-form-item
                  label="采集名称"
                  label-width="150px"
                  class="searchItem"
                  prop="itemName"
              >
                <el-input
                    v-model="sonform.itemName"
                    placeholder="请输入采集名称"
                    clearable
                    maxlength="30" show-word-limit name="limitLength"
                    style="width: 434px; height: 40px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="cow">
              <el-form-item
                  label="必填项"
                  label-width="150px"
                  class="searchItem"
                  prop="requiredFields"
              >
                <template>
                  <el-radio-group v-model="sonform.requiredFields">
                    <el-radio :label="1">必填</el-radio>
                    <el-radio :label="2">非必填</el-radio>
                  </el-radio-group>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="cow">
              <el-form-item
                  label="启用状态"
                  label-width="150px"
                  class="searchItem"
                  prop="generalStatus"
              >
                <template>
                  <el-radio-group v-model="sonform.generalStatus">
                    <el-radio :label="1">启用</el-radio>
                    <el-radio :label="0">停用</el-radio>
                  </el-radio-group>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="cow">
              <el-form-item
                  label="录入类型"
                  label-width="150px"
                  class="searchItem"
                  prop="inputType"
              >
                <template>
                  <el-radio-group v-model="sonform.inputType" @change="onfirstType(sonform.id)">
                    <el-radio :label="1">文本框</el-radio>
                    <el-radio :label="2">单选</el-radio>
                    <el-radio :label="3">多选</el-radio>
                    <el-radio :label="4">上传图片</el-radio>
                    <el-radio :label="5">数字框</el-radio>
                  </el-radio-group>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="cow">
              <el-form-item
                  label="排序"
                  label-width="150px"
                  class="searchItem"
                  prop="sortNum"
              >
                <el-input
                    v-model.number="sonform.sortNum"
                    placeholder="请输入数字0-9999，数字越小，排序越靠前"
                    clearable
                    type="number"
                    maxlength="4"
                    @input="sonform.sortNum= sonform.sortNum>9999  ? 9999 : (( sonform.sortNum< 0) ? 0: sonform.sortNum)"
                    style="width: 434px; height: 40px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="sonform.inputType == 2 || sonform.inputType == 3">
            <el-col
                style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
            >
              <div style="color: #9a9ca1; font-weight: 400; font-size: 14px">
                （最多可一次性添加10个数据）
              </div>
              <el-button
                  type="primary"
                  plain
                  @click="addList(1)"
                  size="mini"
                  class="addbtn"
              >新增</el-button
              >
            </el-col>
          </el-row>
          <el-row
              style="margin-top: 10px;margin-left: 43px"
              v-if="sonform.inputType == 2 || sonform.inputType == 3"
          >
            <el-col>
              <table cellpadding="0" cellspacing="0" class="table2">
                <div>
                  <tr style="">
                    <th style="width: 664px"><span style="color:red">*</span>选项</th>
                    <th style="width: 226px"><span style="color:red">*</span>分类</th>
                    <th style="width: 226px"><span style="color:red">*</span>是否拍照</th>
                    <th style="width: 226px"><span style="color:red">*</span>是否补充描述</th>
                    <th style="width: 228px">操作</th>
                  </tr>
                </div>
                <div v-for="(item, index) in sonform.itemss" :key="index">
                  <tr style="height: 50px;line-height: 50px">
                    <td style="width: 649px" class="itemssConter">
                      <el-form-item
                          :prop="'itemss.' + index + '.content'"
                          :rules="{
                        required: true,
                        message: '',
                        trigger: 'blur',
                      }"
                      >
                        <el-input
                            style="width: 649px;margin-top: 5px"
                            v-model="item.content"
                            placeholder="请输入内容"
                        ></el-input>
                      </el-form-item>
                    </td>
                    <td style="width: 214px">
                      <template>
                        <el-radio-group v-model="item.type">
                          <el-radio :label="1" style="margin-left: 15px"
                          >正常项</el-radio
                          >
                          <el-radio :label="2">异常项</el-radio>
                        </el-radio-group>
                      </template>
                    </td>
                    <td style="width: 215px">
                      <template>
                        <el-radio-group v-model="item.takingPictures">
                          <el-radio :label="1" style="margin-left: 50px"
                          >是</el-radio
                          >
                          <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                      </template>
                    </td>
                    <td style="width: 214px">
                      <template>
                        <el-radio-group v-model="item.describe">
                          <el-radio :label="1" style="margin-left: 50px"
                          >是</el-radio
                          >
                          <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                      </template>
                    </td>
                    <td style="width: 216px">
                      <el-button
                          plain
                          type="danger"
                          @click="delFormList(index)"
                          style="margin-left: 15px"
                      >删除</el-button
                      >
                      <el-button
                          v-if="sonform.inputType!=3"
                          plain
                          type="primary"
                          @click="addListScect(item.content, index)"
                      >新增关联项</el-button
                      >
                    </td>
                  </tr>
                </div>
              </table>
            </el-col>
          </el-row>
        </div>
        <!-- 第二项数组 -->
        <el-form :inline="true" :model="form" ref="form">
          <div
              class="SearchBar1"
              v-for="(el, number) in form.itemsJuniors"
              :key="number"
              style="margin-top: 20px"
          >
            <div style="padding: 23px 0px 36px 30px">
              <span style="font-weight: bolder">{{ el.titleName }}的关联项</span>
              <el-button
                  type="danger"
                  size="mini"
                  plain
                  class="delbtn"
                  @click="delFormRola(number)"
              >删除</el-button
              >
            </div>

            <el-row>
              <el-col class="cow">
                <el-form-item
                    label="采集名称"
                    label-width="150px"
                    :prop="'itemsJuniors.' + number + '.juniorName'"
                    :rules="{
                  required: true,
                  message: '请输入内容',
                  trigger: 'blur',
                }"
                    class="searchItem"
                >
                  <el-input
                      v-model="el.juniorName"
                      placeholder="请输入"
                      clearable
                      style="width: 434px; height: 40px"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="cow">
                <el-form-item
                    label="必填项"
                    label-width="150px"
                    :prop="'itemsJuniors.' + number + '.juniorRequiredFields'"
                    :rules="{
                  required: true,
                  message: '请选择必填项',
                  trigger: 'blur',
                }"
                    class="searchItem"
                >
                  <template>
                    <el-radio-group v-model="el.juniorRequiredFields">
                      <el-radio :label="1">必填</el-radio>
                      <el-radio :label="2">非必填</el-radio>
                    </el-radio-group>
                  </template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="cow">
                <el-form-item
                    label="录入类型"
                    label-width="150px"
                    :prop="'itemsJuniors.' + number + '.juniorInputType'"
                    :rules="{
                  required: true,
                  message: '请选择录入类型',
                  trigger: 'blur',
                }"
                    class="searchItem"
                >
                  <template>
                    <el-radio-group v-model="el.juniorInputType">
                      <el-radio :label="1">文本框</el-radio>
                      <el-radio :label="2">单选</el-radio>
                      <el-radio :label="3">多选</el-radio>
                      <el-radio :label="4">上传图片</el-radio>
                      <el-radio :label="5">数字框</el-radio>
                    </el-radio-group>
                  </template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="el.juniorInputType == 2 || el.juniorInputType == 3">
              <el-col
                  style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
              >
                <div style="color: #9a9ca1; font-weight: 400; font-size: 14px">
                  （最多可一次性添加10个数据）
                </div>
                <el-button
                    type="primary"
                    plain
                    @click="addListFirst(number)"
                    size="mini"
                    class="addbtn"
                >新增</el-button
                >
              </el-col>
            </el-row>
            <el-row
                style="margin: 2% 0 2% 2%"
                v-if="el.juniorInputType == 2 || el.juniorInputType == 3"
            >
              <el-col>
                <table cellpadding="0" cellspacing="0" class="table2">
                  <div>
                    <tr style="">
                      <th style="width: 664px"><span style="color:red">*</span>选项</th>
                      <th style="width: 226px"><span style="color:red">*</span>分类</th>
                      <th style="width: 226px"><span style="color:red">*</span>是否拍照</th>
                      <th style="width: 226px"><span style="color:red">*</span>是否补充描述</th>
                      <th style="width: 228px">操作</th>
                    </tr>
                  </div>
                  <div v-for="(first, num) in el.itemsJuniorss" :key="num">
                    <tr>
                      <td style="width: 649px" class="itemssConter">
                        <el-form-item
                            label=""
                            label-width="100px"
                            :prop="'itemsJuniors.'+number+'.itemsJuniorss.' + num + '.juniorsContent'"
                            :rules="{
                          required: true,
                          message: '请输入内容',
                          trigger: 'blur',
                        }"
                            class="searchItem"
                        >
                          <el-input
                              v-model="first.juniorsContent"
                              style="width: 649px"
                              placeholder="请输入内容"
                          ></el-input>
                        </el-form-item>
                      </td>
                      <td style="width: 214px">
                        <template>
                          <el-radio-group v-model="first.juniorsType">
                            <el-radio :label="1" style="margin-left: 15px"
                            >正常项</el-radio
                            >
                            <el-radio :label="2">异常项</el-radio>
                          </el-radio-group>
                        </template>
                      </td>
                      <td style="width: 215px">
                        <template>
                          <el-radio-group v-model="first.juniorsTakingPictures">
                            <el-radio :label="1" style="margin-left: 50px"
                            >是</el-radio
                            >
                            <el-radio :label="2">否</el-radio>
                          </el-radio-group>
                        </template>
                      </td>
                      <td style="width: 214px">
                        <template>
                          <el-radio-group v-model="first.juniorsDescribe">
                            <el-radio :label="1" style="margin-left: 50px"
                            >是</el-radio
                            >
                            <el-radio :label="2">否</el-radio>
                          </el-radio-group>
                        </template>
                      </td>
                      <td style="width: 216px;text-align: center;">
                        <el-button
                            plain
                            type="danger"
                            @click="delFormListSon(num,number)"
                            style="margin-left: 15px"
                        >删除</el-button
                        >
                      </td>
                    </tr>
                  </div>
                </table>
              </el-col>
            </el-row>
          </div>
        </el-form>

      </el-form>
    </div>
    <div class="btndiv">
      <el-button @click="closeadd">取消</el-button>
      <el-button
          type="primary"
          @click="submit"
          style="margin: 18px 0px 13px 20px;"
      >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  itemInsertOrUpdate,
  itemDetails2
} from "@/RequestPort/peopleUser/people";
export default {
  name: "addCollec",
  data() {
    return {
      radio: "1",
      radios: "1",
      options: [],
      value: "",
      // 关联项
      form: {
        itemsJuniors: [],
      },
      input: "",
      // 表单提交
      sonform: {
        requiredFields: 1,
        generalStatus: 1,
        inputType: 1,
        itemss: [
          {
            type: 2,
            takingPictures: 2,
            describe: 2,
            itemsJuniors: [],
            content: "",
          },
        ],
      },
      // 子项

      rule: {
        itemName: {
          required: true,
          message: "请填写采集名称",
          trigger: "blur",
        },
        requiredFields: {
          required: true,
          message: "请选择是否必填",
          trigger: "change",
        },
        generalStatus: {
          required: true,
          message: "请选择启用状态",
          trigger: "change",
        },
        inputType: {
          required: true,
          message: "请选择录入类型",
          trigger: "change",
        },
        sortNum: [{ required: true, message: "请填写排序", trigger: "blur" }]
      },
      // 子项角标
      cornerMark: 0,
    };
  },
  mounted() {
    if(this.$route.query.id){
      this.particulars(this.$route.query.id)
    }
  },
  methods: {
    /**
     * 获取详情
     */
    particulars(id) {
      let that = this
      itemDetails2({id}).then(res =>{
        that.sonform = res.data
        that.form.itemsJuniors = res.data.itemsJuniors
        res.data.itemss.forEach((el,index) => {
          that.form.itemsJuniors.forEach(item => {
            if(item.pid == el.id) {
              item.titleName=  el.content,
                  item.tyepNUmber= index
            }
          })
        })

      })
    },
    // 动态表格_添加一条
    addList() {
      if (this.sonform.itemss.length < 10) {
        this.sonform.itemss.push({ type: 2, takingPictures: 2, describe: 2 });
      } else {
        this.$message.error("最多十条");
      }
    },
    /**
     * 切换第一个录入类型的时候
     */
    onfirstType(val){
      console.log(val)
      this.sonform.itemss = [
        {
          type: 2,
          takingPictures: 2,
          describe: 2,
          itemsJuniors: [],
          content: "",
        },
      ]
      this.form.itemsJuniors = []
    },
    /**
     * 子项关联表格
     * index 父关联的角标
     * val 父关联名称
     */
    addListScect(val, index) {
      this.cornerMark = index;
      if (!val) {
        this.$message.error("请先填写选项名称");
      } else {
        // 数组拿到第一层数组角标
        this.form.itemsJuniors.push({
          titleName: val,
          tyepNUmber: index,
          juniorInputType: 1,
          juniorRequiredFields: 1,
          itemsJuniorss: [
            {
              juniorsDescribe: 2,
              juniorsTakingPictures: 2,
              juniorsType: 2,
            },
          ],
        });
      }
    },
    /**
     * 新增关联项的子集
     */
    addListFirst(index) {
      if (this.form.itemsJuniors[index].itemsJuniorss.length < 10) {
        this.form.itemsJuniors[index].itemsJuniorss.push({
          juniorsDescribe: 2,
          juniorsTakingPictures: 2,
          juniorsType: 2,
        });
      } else {
        this.$message.error("最多十条");
      }
    },
    // 第一个删除
    delFormList(index) {
      let that = this
      if (that.sonform.itemss.length > 1) {
        that.sonform.itemss.splice(index, 1);
        if(that.form.itemsJuniors && that.form.itemsJuniors.length > 0) {
          that.form.itemsJuniors.forEach((el,number) => {
            if(el.tyepNUmber == index) {
              that.form.itemsJuniors.splice(number, 1);
            }
          })
        }

      } else {
        this.$message.error("至少一条");
      }
    },
    /**
     * 删除子关联
     */
    delFormListSon(index,number){
      if(this.form.itemsJuniors[number].itemsJuniorss.length > 1) {
        this.form.itemsJuniors[number].itemsJuniorss.splice(index,1)
      } else {
        this.$message.error("至少一条");
      }
    },
    //关联项 删除
    delFormRola(index) {
      this.$confirm('是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        this.form.itemsJuniors.splice(index, 1);
      })
    },
    /**
     * 提交
     */
    submit() {
      let that = this
      if(this.sonform.sortNum&&this.sonform.sortNum.length>4) {
        this.$message.error("排序请输入数字0-9999");
        return
      }
      //
      // let data = this.sonform.itemss.filter(el => !el.content)
      // if(data.length>0){
      //   this.$message.error('有数据未填写，请仔细核对');
      //   return
      // }
      // 判断外层数据是否必填
      that.$refs["sonform"].validate((valid) => {
        if (valid) {
          if (that.form.itemsJuniors && that.form.itemsJuniors.length > 0) {
            that.form.itemsJuniors.forEach((el) => {
              if(that.sonform.itemss[el.tyepNUmber].itemsJuniors){
                that.sonform.itemss[el.tyepNUmber].itemsJuniors.push(el);
              } else {
                that.sonform.itemss[el.tyepNUmber].itemsJuniors = [el];
              }
            });
            // 关联项数据是否必填
            that.$refs["form"].validate((valid) => {
              if (valid) {
                itemInsertOrUpdate(that.sonform).then(res => {
                
                  that.$message({
                    message: res.data,
                    type: 'success'
                  });
                  that.closeadd()
                })
              }
            });
          } else {
            itemInsertOrUpdate(that.sonform).then(res => {
                console.log(res)
              that.$message({
                message: res.data,
                type: 'success'
              });
              that.closeadd()
            })
          }
        } else {
          this.$message.error('有数据未填写，请仔细核对');
        }
      });
    },
    //返回
    closeadd() {
      // this.$router.go(-1);
      this.$router.push({ path: '/home/checkDict', query: { first: 2, screcd: 1 } })
    },
  },
};
</script>

<style lang="less" scoped>
.itemssConter ::v-deep .el-form-item__error{display: none;}
.searchItem {
  margin-bottom: 20px;
}
.label {
  color: #909399;
}
// 表格样式
.itemssConter {
  overflow: hidden;
  .el-form-item {
    margin: 0;
  }
  .el-form-item__error {
    display: none;
  }
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  // height: calc(100vh - 280px);
  padding-bottom: 20px;
}
.SearchBar1 {
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  min-height: calc(100vh - 600px);
  padding-bottom: 20px;
}
// .cow{
//   // margin-left: 20px;
// }
.table2 {
  width: 1570px;
  overflow: hidden;
  border: 1px solid #ebebeb;
  tr {
    height: 50px;
  }
  td {
    border: 1px solid #ebebeb;
    padding: 5px;
  }
  th {
    padding-top: 12px;
    border: 1px solid #ebebeb;
    background-color: #f9fafe;
  }
  ::v-deep.el-form-item__error{
    display: none;
  }
}
.addbtn {
  width: 92px;
  height: 38px;
  float: right;
  margin-right: 40px;
}
.backbtn {
  margin: 30px 0px 40px 30px;
  width: 82px;
  height: 36px;
}
.btndiv {
  margin-left: 160px;
}
.heighttes{
  height: calc(100vh - 227px);
  overflow: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow: auto;
  height: calc(100vh - 150px);
}
.delbtn {
  float: right;
  margin-right: 21px;
}

</style>


